import { handleDropdownItemClick } from 'components/imagedropdown/ImageDropdown';
import { useLesion } from 'context/Lesions';
import React from 'react';
import { Images, NiftiUrls, Product, formattedImageData } from 'types';
import { shouldDisplayLesionMenu } from 'utils/heatmaps/heatmaps';
import formatISO8601Date from 'utils/time';
/**
 * Formats an array of images for use in a dropdown component.
 *
 * @param {Images} images - The images object containing metadata for each product.
 * @param {Product} selectedProduct - The selected product type (e.g., 'ce', 'fda', 'res').
 * @param {NiftiUrls} niftiUrls - The Nifti URLs object with image URLs and lesions.
 * @param {string} selectedImage - The ID of the currently selected image.
 * @returns {formattedImageData[]} An array of formatted image data for dropdown display.
 */

export default function formatImageData(
  images: Images,
  selectedProduct: Product,
  niftiUrls: NiftiUrls,
  selectedImage: string,
  setShowLesionMenu: React.Dispatch<React.SetStateAction<boolean>>,
): formattedImageData[] {
  const { setActiveLesion } = useLesion();
  return Object.entries(images[selectedProduct]).map(([imageId, image]) => {
    const niftiData = niftiUrls[imageId];
    const lesions = niftiData.lesions?.[selectedProduct];
    return {
      lesions,
      selectedImage: parseInt(imageId, 10) === parseInt(selectedImage, 10),
      label: `${image.type} ${formatISO8601Date(image.datetime)}`,
      value: niftiData.image,
      onClick: () => {
        setShowLesionMenu(shouldDisplayLesionMenu(selectedProduct, image.type));
        const imageMetaData = {
          image: niftiData.image,
          lesions: lesions ? Object.values(lesions).map((value) => `${value}`) : [],
        };
        handleDropdownItemClick(imageMetaData, setActiveLesion);
      },
    };
  });
}
